<template>
    <div class="detail-nav">
        <div class="navlist">
            <ul class="clearfix">
                <li class="fl nav-item active"><router-link to="/">首页</router-link> </li>
                <li class="fl nav-item"><router-link to="/xiangninghui">乡宁荟</router-link> </li>
                <li class="fl nav-item"><router-link to="/talents">乡宁情</router-link> </li>
                <li class="fl nav-item"><router-link to="/xiangningwang">乡宁旺</router-link> </li>
                <li class="fl nav-item"><router-link to="/resource">资源集市</router-link> </li>
                <li class="fl nav-item"><router-link to="/sanxiangxueyuan">三农学院</router-link> </li>
                <li class="fl nav-item"><router-link to="/sanxiangguangbo">三农广播</router-link> </li>
            </ul>
        </div>
        
        <div class="clearfix pageh2 w-1400">
            <div class="nav-name fl">西宁三农信息服务平台</div>
            <div class="searchBox">
                <div class="container" :class="isShowSearch?'':'search-all-bottom'">
                    <el-input v-model="inputValue" :class="isShowSearch?'input2':''" style="width: 320px" placeholder="输入关键词查询"
                    @input="getSearch" @focus="showSearch" @blur="hideSearch"></el-input>
                    <el-button type="primary" @click="getAccurateSearch">搜索</el-button>
                    <div class="search-box" v-show="isShowSearch">
                    <div v-for="(sear,index) in showData" class="search-list" :key="index" @mouseenter="getSearchList(sear.url)" @mouseleave="clearSearchList">
                        <a :href="sear.url" @click="aHideSearch2">
                        <div :class="sear.url == mouseKey ? 'search-title' :''">{{sear.title}}</div>
                        </a>
                    </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return{
            inputValue: "",
            showData: [],
            mouseKey: "",
            isShowSearch: false,
            isControlShow: true,
            sourceData: [
                {
                title: "乡宁荟",
                url: "/xiangninghui",
                },
                {
                title: "乡宁游",
                url: "/xiangninghui",
                },
                {
                title: "共享农庄",
                url: "/xiangninghui/sharehouse",
                },
                {
                title: "乡宁购",
                url: "/xiangninghui/shopping",
                },
                {
                title: "乡宁情",
                url: "/xiangningqing",
                },
                {
                title: "能人风采",
                url: "/talents",
                },
                {
                title: "人才档案",
                url: "/Personnelfile",
                },
                {
                title: "乡宁旺",
                url: "/business",
                },
                {
                title: "招商引资",
                url: "/business",
                },{
                title: "企业展示",
                url: "/enterprise",
                },{
                title: "企业直聘",
                url: "/recruit",
                },
                {
                title: "资源集市",
                url: "/resource",
                },
                {
                title: "盘活资源",
                url: "/resource",
                },
                {
                title: "企业资源",
                url: "/enterpriseResource",
                },
                {
                title: "产权交易",
                url: "/equity",
                },
                {
                title: "三农学院",
                url: "/server",
                },
                {
                title: "三农服务",
                url: "/server",
                },
                {
                title: "三农课堂",
                url: "/school",
                },
                {
                title: "创客空间",
                url: "/creater",
                },
                {
                title: "三农广播",
                url: "/dynamic",
                },
                {
                title: "三农动态",
                url: "/dynamic",
                },
                {
                title: "政策红包",
                url: "/policy",
                },
                {
                title: "数据中心",
                url: "/datas",
                },
            ],
        }
    },
    methods: {
      /**
       * 根据输入的值进行本地的模糊搜索
       * 聚焦输入框的时候，没有输入字段，先展示推荐内容
       * 如果输入字段，有则显示匹配信息，没有则不显示
       * */
    getSearch() {
      let keyWord = this.inputValue.toLowerCase();
      let arr = [];
      // sourceData: 数据源
      arr = this.sourceData.filter((res) => {
        if (res.title.toLowerCase().indexOf(keyWord) !== -1) {
          return res;
        }
      });
      // showData:展示的数据
      this.showData = arr;
       // 聚焦输入框的时候，没有输入字段，先展示推荐内容
      this.inputValue == "" ? (this.showData = this.sourceData) : '';
      // 输入字段有匹配到信息，显示下拉框，没有则隐藏下拉框
      this.showData.length == 0
        ? (this.isShowSearch = false)
        : (this.isShowSearch = true);
    },
    // 监听输入框获取焦点
    showSearch() {
      if (this.inputValue == "") {
        this.isShowSearch = true;
        this.showData = this.sourceData;
      } else {
      // 输入字段没有匹配到信息,隐藏下拉框
        if (this.showData.length == 0) {
          this.isShowSearch = false;
        } else {
          this.isShowSearch = true;
        }
      }
    },
    /**
       * 输入框失去焦点的时候触发
       * 判断是不是进入搜索详情区域的失去焦点
       * 如果是点击搜索详情区域的，搜索详情区域不隐藏
       * 点击其它区域，搜索详情区域隐藏
       * */
    hideSearch() {
      this.showData.length == 0 ? (this.isShowSearch = false) : "";
      this.isControlShow ? "" : (this.isShowSearch = false);
    },
    // 点击的时候隐藏搜索详情区域
    aHideSearch2() {
      this.isShowSearch = false;
    },
    // 鼠标移入搜索详情区域的时候改变文字颜色
    getSearchList(e) {
      this.mouseKey = e;
      this.isControlShow = true;
    },
    // 鼠标移出搜索详情区域的时候清空文字颜色
    clearSearchList() {
      this.mouseKey = "";
      this.isControlShow = false;
    },
    // 发送给后台进行深层次的模糊搜索
    getAccurateSearch() {},
  },
  created(){
      this.showData=JSON.parse(JSON.stringify(this.sourceData))
  },
};
</script>

<style scoped>
.search-list:hover{
  background: #eee;
}
.search-list{
  font-size: 14px;
  padding-left: 12px;
}
.container{
  position: relative;
}
.searchBox >>> .el-button{
    border-radius: 0;
    /* transform: translateY(-1px); */
}
.searchBox >>> .el-button--primary{
    background-color: #0169ca;
    border-color: #0169ca;
}
.searchBox >>> .el-input__inner{
    border-color: #0169ca;
    border-radius: 0;
}
.searchBox{
    width: 50%;
    height: 150px;
    line-height: 150px;
}
.nav-name{
    width: 50%;
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    line-height: 150px;
}
.navlist li:hover,.navlist li.act{
    background: rgba(255, 255, 255, .3);
}
.navlist li{
    padding:0 20px
}
.navlist ul{
    width: 1400px;
    margin: 0 auto;
}
.navlist{
    height: 50px;
    line-height: 50px;
    background: #0e6ac1;
    color: #fff;
    font-size: 20px;
    font-weight: 800;
}
.navlist a{
    color: #fff;
}
.detail-nav{
    height: 430px;
    background: url(../assets/img/detail-navbg.png) no-repeat;
}

</style>