<template>
  <div>
  <div class="bestway">
    <div class="bestway-main">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/xiangninghui' }"
            >乡宁荟</el-breadcrumb-item
          >
          <!-- <el-breadcrumb-item :to="{ path: '/bestway' }"
            >精品路线</el-breadcrumb-item
          > -->
          <el-breadcrumb-item>{{params.filter.routesTypes}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="way-title clearfix">
        <div
          class="fl"
          :class="{act:params.filter.routesTypes == '精品路线'}"
          @click="
            params.filter.routesTypes = '精品路线';
            params.currentPage = 1;
            getPageList();
          "
        >
          精品路线列表
        </div>
        <div
          class="fl"
          :class="{act:params.filter.routesTypes == '徒步自驾'}"
          @click="
            params.filter.routesTypes = '徒步自驾';
            params.currentPage = 1;
            getPageList();
          "
        >
          徒步自驾列表
        </div>
      </div>
      <div class="way-list">
        <div class="way-item clearfix" v-for="item in pageData.list" :key="item.id">
          <router-link :to="`/bestwaydetail?id=${item.id}`">
            <div class="fl way-item-pic">
              <el-image
                style="width: 100%; height: 100%"
                :src="item.photoUrls.split(',')[0]"
              ></el-image>
              <div class="way-market">
                <div class="way-day">{{item.playTimes}}</div>
                <div class="sanjiao"></div>
              </div>
            </div>
            <div class="fr way-item-info">
              <div class="way-h4">{{ item.name }}</div>
              <div class="way-intro">
                {{ item.routesFeatures }}
              </div>
              <div class="way-flow">
                <i class="el-icon-location-information"></i>
                {{ item.trafficStrategy }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <el-pagination class="pagination" :current-page="params.currentPage" background layout="prev, pager, next,jumper" :total="pageData.total" @current-change="handleCurrentChange"> </el-pagination>
  </div>
</template>
<script>
import detailNav from "@/components/detailnav";
import {GetTouristRoutesPage} from "@/api/home.js";
export default {
  components: {
    detailNav,
  },
  data() {
    return {
      pageData: {},
      params: {
        currentPage: 1,
        filter: { routesTypes: "精品路线" },
        pageSize: 10,
      },
    };
  },
   mounted(){
        this.getPageList();
    },
    methods: {
    getPageList() {
      GetTouristRoutesPage(this.params).then((res) => {
        const data = res.data.data;
        this.pageData = data;
    
      });

    },
    handleCurrentChange(page){
            this.params.currentPage=page;
            this.getPageList();
        }
  }
};
</script>
<style scoped>
.bestway{
  background-image: url('../../../assets/img/way/detail-bg.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 20px;
}
.pagination{
  margin:20px 0;
}
.way-list {
  padding-top: 10px;
}
.sanjiao {
  border-left: 20px solid #ffb400;
  border-right: 20px solid transparent;
  border-bottom: 40px solid transparent;
}
.way-day {
  padding: 8px 30px;
  background: #ffb400;
  font-size: 24px;
  color: #fff;
}
.way-market {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
}
.way-flow {
  line-height: 28px;
  font-size: 14px;
}
.way-intro {
  line-height: 32px;
  font-size: 16px;
  /* min-height: 160px; */
  color: #666;
  text-indent: 30px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.way-h4 {
  line-height: 50px;
  font-size: 24px;
  font-weight: 700;
}
.way-item-info {
  width: calc(100% - 500px);
  height: 280px;
  overflow: hidden;
  padding: 20px;
  border: 1px solid #939393;
}
.way-item-pic {
  width: 500px;
  height: 280px;
  position: relative;
}
.way-item {
  margin-top: 20px;
  font-family: "微软雅黑";
}
.way-title div:last-child {
  border-left: 2px solid #939393;
}
.way-title div:hover,
.way-title div.act {
  color: #0e6ac1;
}
.way-title div {
  padding: 0px 20px;
  cursor: pointer;
}
.way-title div:first-child {
  padding-left: 0;
}
.way-title {
  margin-top: 50px;
  padding: 15px 0;
  height: 50px;
  font-size: 20px;
  font-weight: 900;
  border-bottom: 2px solid #0e6ac1;
}
.bestway-main {
  width: 1200px;
  margin: 0px auto;
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: "微软雅黑";
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
</style>>